<template>
  <div class="about pa-3">
    <h1>AGCO</h1>
    <v-list>
      <v-list-item> Application : 001 </v-list-item>
      <v-list-item> Vue : {{ vueVersion }} </v-list-item>
      <v-list-item> Vuetify : {{ vuetifyVersion }} </v-list-item>
      <v-list-item> Développeur : Marc Duren </v-list-item>
    </v-list>
  </div>
</template>
<script>
import Vuetify from 'vuetify/lib'
import Vue from 'vue'

export default {
  data() {
    return {
      vueVersion: Vue.version,
      vuetifyVersion: Vuetify.version
    }
  }
}
</script>
