





































































































import Vue from 'vue'
import axios from 'axios'
import Confirm from '@/components/Confirm.vue'

const tableDefinition = {
  table_nom: 'plg_transporteur',
  table_pk: 'transporteur_id', // clé primaire
  table_colonnes: [
    { col_nom: 'transporteur_id', col_type: 'n' }, // int(11) NOT NULL,
    { col_nom: 'transporteur_nom', col_type: 's' }, // varchar(255) NOT NULL,
    { col_nom: 'transporteur_pass', col_type: 's' } // varchar(255) NOT NULL,
  ],
  sqlSelect: 'SELECT * FROM plg_transporteur ORDER BY transporteur_id',
  sqlUpdate: 'transporteur_id=?',
  sqlOrderBy: 'ORDER BY transporteur_id',
  sqlDelete: 'transporteur_id=?'
}
interface IColonneDefinition {
  label: string
  field: string
  sortable: boolean
  align: string
  width: number
  filter: string | null
}

const colonnesDefinition: IColonneDefinition[] = [
  // colonnes visibles
  {
    label: 'Transporteur',
    field: 'transporteur_nom',
    sortable: true,
    align: 'left',
    width: 150,
    filter: ''
  },
  {
    label: 'Mot de passe',
    field: 'transporteur_pass',
    sortable: false,
    align: 'left',
    width: 350,
    filter: ''
  }
]

interface FormControle {
  label: string
  field: string
  value: string | null
  default_value?: string | null
  help: string
  options: Array<{ label: string; value: string }> | null
  join: any
  aide?: string
}
const formDefinition: FormControle[] = [
  { label: 'Transporteur', field: 'transporteur_nom', value: '', help: '', options: null, join: null },
  { label: 'Mot de passe', field: 'transporteur_pass', value: '', help: '', options: null, join: null }
]
interface Enregistrement {
  /**Ligne base de données (field)*/
  pk: string | null
  visible: true
  [propName: string]: any
}

export default Vue.extend({
  name: 'Trp',
  props: ['contentHeight'],
  components: { Confirm },
  computed: {
    tableHeight(): number {
      return this.contentHeight - 54
    }
  },
  data() {
    return {
      bloque: true,
      snackbar: false,
      urlapi: process.env.NODE_ENV === 'development' ? 'http://localhost/agco/agco-planningtransporteurs/php/' : './php/',
      afficherModal: false, // afficher modal
      formulaire: formDefinition,
      valeurs: { pk: null, visible: true } as Enregistrement,
      pk: null as string | null,
      colonnes: colonnesDefinition,
      enregistrements: [] as Enregistrement[],
      lien: '',
      motdepasse: '',
      showPass: false,
      adminPass: 'JCCM18'
    }
  },
  methods: {
    actualiser() {
      this.charger()
    },
    validerPass() {
      if (this.motdepasse === this.adminPass) {
        this.bloque = false
      }
    },
    charger() {
      axios
        .get(this.urlapi + 'ajax_tables.php', {
          params: {
            action: 'LECTURE',
            tabledef: tableDefinition
          }
        })
        .then((response) => {
          if (response.data.enregistrements) {
            this.enregistrements = response.data.enregistrements
          }
          this.pk = null
          for (let p = 0; p < this.formulaire.length; p++) {
            this.formulaire[p].value = ''
          }
          this.filtrer()
        })
    },
    filtrer() {
      for (let i = 0; i < this.enregistrements.length; i++) {
        const ENRE = this.enregistrements[i]
        let visible = true
        this.colonnes.forEach((col) => {
          if (col.filter !== '' && col.filter != null) {
            const regex1 = new RegExp(col.filter, 'i')
            const s = ENRE[col.field]
            if (!regex1.test(s)) {
              visible = false
            }
          }
        })
        ENRE.visible = visible
      }
    },
    selDetail(j: number) {
      const ligne = this.enregistrements[j]
      this.pk = ligne.pk
      for (let p = 0; p < this.formulaire.length; p++) {
        const field = this.formulaire[p].field
        this.formulaire[p].value = ligne[field]
      }
      let trp: string = ligne.transporteur_nom
      //trp=trp.replaceAll(' ','%2F')
      this.lien = 'http://planningtransporteurs.fr/#/' + trp + '/' + ligne.transporteur_pass
    },
    modifier() {
      for (const V of this.formulaire) {
        if (V.field === tableDefinition['table_pk']) {
          this.pk = V.value
          this.valeurs.pk = V.value
        }
        this.valeurs[V.field] = V.value
      }
      this.afficherModal = true
    },
    nouveau() {
      this.pk = null
      this.valeurs.pk = null
      for (let n = 0; n < this.formulaire.length; n++) {
        const E = this.formulaire[n]
        let v = ''
        if (E.default_value) v = E.default_value
        this.formulaire[n].value = v
        this.valeurs[E.field] = v
      }
      this.afficherModal = true
    },
    enregistrer() {
      this.afficherModal = false
      for (const V of this.formulaire) {
        this.valeurs[V.field] = V.value
      }
      axios
        .post(this.urlapi + 'ajax_tables.php', {
          action: 'ENREGISTRER',
          tabledef: tableDefinition,
          pk: this.pk,
          enregistrement: this.valeurs
        })
        .then((response) => {
          if (response.data.rep === 'OK') {
            this.snackbar = true
            this.charger()
          }
          if (response.data.rep === 'ERREUR') {
            alert(response.data.erreur)
          }
        })
    },
    async supprimer() {
      const conf: any = this.$refs.confirm
      if (conf) {
        conf.openDialogConfirm('Supprimer', 'Confirmer la suppression', { color: 'red' }).then((rep: any) => {
          if (rep) {
            this.afficherModal = false
            axios
              .post(this.urlapi + 'ajax_tables.php', {
                action: 'SUPPRIMER',
                tabledef: tableDefinition,
                pk: this.pk
              })
              .then((response) => {
                if (response.data.rep === 'OK') {
                  this.snackbar = true
                  this.charger()
                }
                if (response.data.rep === 'ERREUR') {
                  alert(response.data.erreur)
                }
              })
          }
        })
      }
    }
  },
  created() {
    this.charger()
    this.$emit('reduire')
    //}
  }
})
