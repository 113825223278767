<template>
  <div class="about pa-3">
    <h1>AGCO parts Ennery</h1>
  </div>
</template>
<script>
import Vuetify from 'vuetify/lib'
import Vue from 'vue'

export default {
  data() {
    return {
      vueVersion: Vue.version,
      vuetifyVersion: Vuetify.version
    }
  }
}
</script>
